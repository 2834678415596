<template>
  <b-card no-body>
    <b-card-body class="d-inline-flex w-100 justify-content-between">
      <div>
        <h4>Manage Model Snapshots</h4>
        <p>
          Save and track the evolution of models by creating snapshots.
          Preserve key stages of your model for easy reference, comparison, and experimental capabilities.
        </p>
      </div>
      <div>
        <b-button
          v-if="!creating"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          :disabled="loading || creating"
          variant="outline-success"
          @click="createSnapshot"
        >
          <span>
            <feather-icon icon="PlusIcon" />
            Create Snapshot
          </span>
        </b-button>
        <b-button
          v-else
          disabled
          variant="outline-success"
          class="mr-1"
        >
          <b-spinner small class="mr-1" />
          Creating...
        </b-button>
      </div>
    </b-card-body>

    <!-- Snapshots Table -->
    <model-snapshot-table
      :table-data="modelSnapshots"
      :model-id="modelId"
      :loading="loading"
      @snapshot-modal-closed="getSnapshots"
    />
  </b-card>
</template>

<script>
import { computed, ref } from '@vue/composition-api'
import ModelSnapshotTable from '@/views/Models/ModelSnapshotTable.vue'
import {
  BButton,
} from 'bootstrap-vue'
import { useRouter } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import coreService from '@/libs/api-services/core-service'

export default {
  name: 'ModelsExport',
  directives: {
    Ripple,
  },
  components: {
    BButton,
    ModelSnapshotTable,
  },
  setup() {
    const { route, router } = useRouter()
    const routeParams = computed(() => route.value.params)
    const modelId = ref(routeParams.value.modelId)
    const modelSnapshots = ref([])

    const loading = ref(false)
    const creating = ref(false)

    const getSnapshots = () => {
      loading.value = true
      coreService
        .get(`/v1/model/${routeParams.value.modelId}/snapshot`)
        .then(({ data }) => { modelSnapshots.value = data }).catch(e => console.error(e))
        .finally(() => { loading.value = false })
    }
    getSnapshots()

    const createSnapshot = () => {
      creating.value = true
      coreService
        .post(`/v1/model/${routeParams.value.modelId}/snapshot`)
        .then(({ data }) => {
          getSnapshots()
        }).catch(e => console.error(e))
        .finally(() => { creating.value = false })
    }

    return {
      modelSnapshots,
      createSnapshot,
      getSnapshots,
      loading,
      creating,
      modelId,
    }
  },
}
</script>
